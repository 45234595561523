<template>
    <div class="row">
        <div class="col-12 text-end">
            <a class="text-primary isLink" data-bs-toggle="collapse" href="#legend" role="button" aria-expanded="false" @click="showLegend = !showLegend" aria-controls="collapseExample">
                <fa icon="angle-up" v-if="showLegend" class="me-2"/>
                <fa icon="angle-down" v-else class="me-2"/>
                Legend
            </a>
        </div>

        <div class="collapse" id="legend">

            <div class="border-top mx-0 pb-3 mt-3 pt-3">
                <div class="lblTiny">
                    <div v-if="folderStatus === true || folderStatus === 'true'" class="row mb-4">
                        <div class="col-12 mb-3 fw-bold">Folder Status</div>

                        <div class="col-4 col-lg-2 mb-2"><span class="badge bg-success">WIP</span></div>
                        <div class="col-8 col-lg-10 mb-2">Editor are working on the folder and documents.</div>

                        <div class="col-4 col-lg-2 mb-2"><span class="badge bg-pink">CIRCULATING</span></div>
                        <div class="col-8 col-lg-10 mb-2">Folder is being circulated to signees.</div>

                        <div class="col-4 col-lg-2 mb-2"><span class="badge bg-purple">SEALED</span></div>
                        <div class="col-8 col-lg-10 mb-2">All documents are signed and verified. We have sealed your documents in the folder and kept them in a secure shelf.</div>
                    </div>

                    <div v-if="docStatus === true || docStatus === 'true'" class="row">
                        <div class="col-12 lblTiny mb-3 fw-bold">Document Status</div>

                        <div class="col-4 col-lg-2 mb-2"><fa icon="file" size="lg" class="text-info ms-1 me-2" />DRAFT</div>
                        <div class="col-8 col-lg-10 mb-2">Waiting for the editor(s) to finalize the document and send invitations to signees.</div>

                        <div class="col-4 col-lg-2 mb-2"><fa icon="envelope" size="lg" class="text-success me-2" />INVITED</div>
                        <div class="col-8 col-lg-10 mb-2">Invitation is sent.</div>

                        <div class="col-4 col-lg-2 mb-2"><fa icon="signature" size="lg" class="text-success me-1" />SIGNED</div>
                        <div class="col-8 col-lg-10 mb-2">Signing in progress.</div>

                        <div class="col-4 col-lg-2 mb-2"><fa icon="check-double" size="lg" class="text-pink me-1" />ALLSIGNED</div>
                        <div class="col-8 col-lg-10 mb-2">Document is fully signed by all signees.</div>        

                        <div class="col-4 col-lg-2 mb-2"><fa icon="eye" size="lg" class="text-brown me-1" />VIEWONLY</div>
                        <div class="col-8 col-lg-10 mb-2">All documents are viewable by default.</div>            

                        <div class="col-4 col-lg-2 mb-2"><fa icon="rotate-right" size="lg" class="text-orange me-2" />REVISE</div>
                        <div class="col-8 col-lg-10 mb-2">Document is reopen for edit purpose.</div>       
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'Legend',
    components: { Popper },
    props: [ 'folderStatus', 'docStatus' ],
    setup() {

        const showLegend = ref(false)

        /* onMounted(() => {
            const obj = document.querySelector('#legend')
            // Save object changes when trigger select signee modal
            obj.addEventListener("hide.bs.collapse", function (event) {
                console.info('------ Trigger close')
                showLegend.value = false
            })

            obj.addEventListener("show.bs.collapse", function (event) {
                console.info('------ Trigger shown')
                showLegend.value = true
            })
        }) */

        return { showLegend }
    }
}
</script>

<style>
.legendContainer {
    width: 55%; 
    margin: 40px auto 0px auto;
    padding: 35px;
    border-radius: 10px;
}

.lblLegend a:hover {
    background-color: #EEEEEE;
    color: #6E777E;
    padding: 5px 8px;
    border-radius: 5px;
}
</style>